import { CSSObject } from '@emotion/core';
import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';

import { ProductDetail } from '@api/catalog';

import { Button, colors, scale } from '@scripts/gds';
import { useCompareHelper } from '@scripts/hooks/useCompareHelper';
import { useFavoriteHelper } from '@scripts/hooks/useFavoriteHelper';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

import HeartIcon from '@icons/20/heart.svg';
import CompareIcon from '@icons/24/compare.svg';
import FilledHeartIcon from '@icons/24/heart.svg';

const AuthPopup = dynamic(() => import('@components/Auth'), { ssr: false });

const btnStyle: CSSObject = {
    height: scale(1, false, 28),
    width: scale(1, false, 28),
    border: '1px solid #F4F4F4 !important',
    backgroundColor: '#ffffff !important',
    borderRadius: '50% !important',
};

const CardTopBar = ({ product }: { product: ProductDetail }) => {
    const { apiUser } = useGetUserData();

    const { compareProductHandler, isCompared } = useCompareHelper({ product });

    const [isAuthPopup, setIsAuthPopup] = useState(false);

    const { favoriteHelper, isFavorite } = useFavoriteHelper({ product, setIsAuthPopup });

    const addFavoritesHandler = useCallback(async () => {
        favoriteHelper(product.id);
    }, [product, isFavorite, apiUser]);

    return (
        <div
            css={{
                display: 'flex',
                flexDirection: 'column',
                gap: scale(1),
            }}
        >
            <Button
                data-testid="button-favorite"
                size="custom"
                theme="ghost"
                hidden
                Icon={isFavorite ? FilledHeartIcon : HeartIcon}
                onClick={addFavoritesHandler}
                css={{
                    ...btnStyle,
                    '> svg': {
                        fill: isFavorite ? colors.primary : colors.textMainBlack,
                        '&:hover': {
                            fill: colors.primary,
                        },
                    },
                }}
            >
                Избранное
            </Button>

            <Button
                data-testid="button-reconsilation"
                size="custom"
                theme="ghost"
                css={{
                    ...btnStyle,
                    fill: isCompared ? colors.primary : colors.textMainBlack,
                    '> svg': {
                        '&:hover': {
                            fill: colors.primary,
                        },
                    },
                }}
                onClick={() => compareProductHandler(product.id, product)}
                Icon={CompareIcon}
                hidden
            >
                Сравнение
            </Button>

            <AuthPopup isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </div>
    );
};

export default CardTopBar;
